import { withStyles } from '@material-ui/core';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import SelectionEditor from '../SelectionEditor/SelectionEditor';
import Switch from '@material-ui/core/Switch';

const styles = (theme) => ({
    controlWrapper: {
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(0),
            marginBottom: theme.spacing(2)
        }
    },
    controlContainer :{
        padding: theme.spacing(2),
        width: '305px',
        height: '305px'
    },
    accordianWrapper: {
        paddingLeft: theme.spacing(0),
        width: '100%',
        marginTop: theme.spacing(2)
    },
    controlsWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    settingsWrapper: {
        width: '626px',
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    accordianContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    accordianDetail: {
        justifyContent: 'center',
        borderTop: '1px solid rgba(0, 0, 0, .125)'
    },
    list: {
        padding: theme.spacing(0)
    },
    container: {
        minWidth: '250px',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    disabled: {
        backgroundColor: theme.colorScheme.componentDisabled,
        pointerEvents: 'none'
    }
});

class SuggestionsControl extends React.Component {
    constructor(props) {
        super(props);
        
        let selections = {
            genres: {
                name: 'Genres',
                list: [ 'Dance', 'Hip Hop', 'Classic Rock', 'Metal', 'Country'],
                selected: [],
                enabled: false
            },
            artists: {
                name: 'Artists',
                list: [ 'Kaskade', 'Kanye', 'Chris Brown', 'Diplo', 'Ghost', 'The Airborne Toxic Event', 'Stone Sour'],
                selected: [],
                enabled: false
            },
            albums: {
                name: 'Albums',
                list: [ 'Prequelle', 'Power Up', 'Strange Desire', 'Don\'t Forget About Me', '18 Months', 'Recovery'],
                selected: [],
                enabled: false
            },
            songs: {
                name: 'Songs',
                list: [ 'Plain Jane', 'TNT', 'Sunshine', 'Crushin\' It', 'Nocturne', 'Run', 'Right Round'],
                selected: [],
                enabled: false
            }
        };

        this.state = {
            selections: selections,
        }
    };

    handleChange = (event) => {
        this.setState(prev => {
            let selections = Object.assign({}, prev.selections);
            selections[event.target.name].enabled = event.target.checked;
            return { selections };
        });
    };
    
    itemRemoved = (key, value) => {
        this.setState(prev => {
            if(prev.selections[key].selected.includes(value)) {
                let selections = Object.assign({}, prev.selections);
                selections[key].selected = selections[key].selected.filter(val => val !== value);
                return { selections };
            }
        });
    }

    itemAdded = (key, value) => {
        this.setState(prev => {
            if(!prev.selections[key].selected.includes(value)) {
                let selections = Object.assign({}, prev.selections);
                selections[key].selected.push(value);
                return { selections };
            }
        });
    }

    render() {
        const classes = this.props.classes;
        const size = this.props.size;
        const isExtraSmall = ['xs'].includes(size);

        const accordions = Object.keys(this.state.selections).filter((key) => this.state.selections[key].enabled).map((key) => {
            return (
                <Accordion key={'acc-'+key}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                        <ListItemText
                            primary={this.state.selections[key].name}
                        />
                    </AccordionSummary>
                    <AccordionDetails divider className={classes.accordianDetail}>
                        <SelectionEditor 
                            list={this.state.selections[key].list}
                            selected={this.state.selections[key].selected}
                            display={this.state.selections[key].name}
                            type={key}
                            onRemoveItem={this.itemRemoved}
                            onAddItem={this.itemAdded}
                        />
                    </AccordionDetails>
                </Accordion>
            );
        });
        const accordionSection = (
            <div className={classes.accordianWrapper}>
                {accordions}
            </div>
        );

        const controls = Object.keys(this.state.selections).filter((key) => this.state.selections[key].enabled).map((key) => {
            const disabledClass = this.state.selections[key].enabled ? `${classes.controlContainer}` : `${classes.controlContainer} ${classes.disabled}`
            return (
                <div key={'ctl-'+key} className={classes.container}>
                    <Paper className={disabledClass}>
                        <SelectionEditor 
                            list={this.state.selections[key].list}
                            selected={this.state.selections[key].selected}
                            display={this.state.selections[key].name}
                            type={key}
                            onRemoveItem={this.itemRemoved}
                            onAddItem={this.itemAdded}
                            disabled={!this.state.selections[key].enabled}
                        />
                    </Paper>
                </div>
            );
        });
        const controlSection = (
            <div className={classes.controlsWrapper}>
                    {controls}
            </div>
        );

        const settingsBlock = Object.keys(this.state.selections).map((key) => {
            return (
                <FormControlLabel
                    key={'sw-'+key}
                    aria-label={'sw-'+key}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    label={this.state.selections[key].name}
                    control={<Switch color="secondary" checked={this.state.selections[key].enabled} onChange={this.handleChange} name={key} />}
                />
            );
        });

        const settingsLabel = isExtraSmall ? (<FormLabel>Suggestions</FormLabel>) : [];

        const settingsBlockSection = (
            <div className={classes.settingsContainer}>
                <Paper className={classes.settingsWrapper}>
                    {settingsLabel}
                    <div>
                        {settingsBlock}
                    </div>
                </Paper>
            </div>
        );

        const controlType = isExtraSmall ? accordionSection : controlSection;
        const responsiveControls = Object.keys(this.state.selections).map(s => this.state.selections[s]).filter(s => s.enabled).length > 0 ? controlType : [];
        
        return (
            <div className={classes.accordianContainer}>
                {settingsBlockSection}
                {responsiveControls}
            </div>
        );
    };
}

export default withStyles(styles)(SuggestionsControl);