import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase/';
import CheckIcon from '@material-ui/icons/Check';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/';
import Paper from '@material-ui/core/Paper/';
import Popper from '@material-ui/core/Popper/';
import React from 'react';

const styles = (theme) => ({
    colorBox: {
        width: '35px',
        height: '35px'
    },
    colorBoxFiller: {
        width: '100%',
        height: '100%',
    },
    colorSelectorWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonWrapper: {
        paddingBottom: '-6px'
    },
    outline: {
        borderColor: theme.colorScheme.componentBackground,
        borderWidth: '2px',
        borderStyle: 'solid'
    },
    icon: {
        color: theme.colorScheme.componentBackground
    }
});

class ColorPicker extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        this.state = {
            anchorEl: null,
            open: false
        };
    };

    handleClick(e) {
        this.setState(prev => {
            let anchorEl = Boolean(prev.anchorEl) ? null : e.target;
            let open = !prev.open
            return { anchorEl, open };
        });
    }

    handleSelect(color) {
        this.props.colorSelected(color);
    }

    render() {
        const classes = this.props.classes;
        const open = Boolean(this.state.anchorEl);

        const colorButton = (
            <Button onClick={this.handleClick} value={this.props.selected}>
                <span className={classes.colorBox}>
                    <div className={`${classes.colorBoxFiller} ${classes.iconContainer}`} style={{ backgroundColor: this.props.selected }}>
                    </div>
                </span>
            </Button>
        );

        const colorbuttons = this.props.colors.map((color) => {
            let selectedClasses = color === this.props.selected ? `${classes.colorBoxFiller} ${classes.iconContainer} ${classes.outline}` : `${classes.colorBoxFiller} ${classes.iconContainer}`;
            let icon = color === this.props.selected ? <CheckIcon className={classes.icon}/> : null;
            return (
                <ButtonBase key={`clr-${color}`} onClick={() => this.handleSelect(color)} value={color}>
                    <span className={classes.colorBox}>
                        <div className={selectedClasses} style={{ backgroundColor: color }}>
                            {icon}
                        </div>
                    </span>
                </ButtonBase>
            );
        });

        return (
            <ClickAwayListener onClickAway={() => this.handleClick({ target: null })}>
                <div className={classes.buttonWrapper}>
                    {colorButton}
                    <Popper
                        open={open}
                        anchorEl={this.state.anchorEl}
                        placement='bottom-start'
                    >
                        <Paper>
                            <div className={classes.colorSelectorWrapper}>
                                {colorbuttons}
                            </div>
                        </Paper>
                    </Popper>
                </div>
            </ClickAwayListener>
        );
    };
}

export default withStyles(styles)(ColorPicker)