import { fade, makeStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.primary.light, 0),
        '&:hover': {
          backgroundColor: fade(theme.palette.primary.light, 0.9),
        },
        width: '75%',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '20ch',
        },
    },
      disabled: {
        backgroundColor: theme.colorScheme.componentDisabled,
        pointerEvents: 'none'
    },
    icon: {
        fill: theme.colorScheme.componentAccent
    }
}));

export default function SearchBar(props) {
    const classes = useStyles();
    const searchDisabled = props.disabled ? `${classes.search} ${classes.disabled}` : `${classes.search}`;

    return (
        <div className={searchDisabled}>
            <div className={classes.searchIcon}>
              <SearchIcon className={classes.icon}/>
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    );
}