import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import NavDrawer from '../NavDrawer/NavDrawer';
import React from 'react';
import Toast from '../Toast/Toast';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        flexGrow: 1
    },
    navBar: {
        backgroundColor: theme.colorScheme.componentBackground,
    },
    navTitle: {
        flexGrow: 1,
        color: theme.colorScheme.primaryAccentText,
        fontFamily: 'Yellowtail'
    },
    loginButton: {
        color: theme.colorScheme.text
    }
});

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggleDrawer = this.toggleDrawer.bind(this);

        this.state = {
            drawerOpen: false
        }
    }

    toggleDrawer() {
        this.setState(prev =>{ 
            let drawerOpen = !prev.drawerOpen;
            return { drawerOpen };  
        });
    }

    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <AppBar position="static" className={classes.navBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.toggleDrawer} >
                            <Toast />
                        </IconButton>
                        <Typography variant="h3" align="center" className={classes.navTitle}> 
                            Slippery Toast
                        </Typography>
                        <Button edge="end" color="inherit" className={classes.loginButton} >Login</Button>
                    </Toolbar>
                </AppBar>
                <NavDrawer open={this.state.drawerOpen} closeDrawer={this.toggleDrawer} >
                </NavDrawer>
            </div>
        );
    }
}

export default withStyles(styles)(NavBar);