import { withStyles } from '@material-ui/core/styles';
import { drawPropertiesGraph, drawMouseLocation } from './DrawProperties';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import PropertiesGraph from '../PropertiesGraph/PropertiesGraph';
import PropertiesSettings from '../PropertiesSettings/PropertiesSettings';
import React from 'react';
import { Collapse } from '@material-ui/core';

const styles = (theme) => ({
    propertySettingsContainer: {
        width: '626px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    propertiesControlContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    accordionDetail: {
        display: 'block',
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        padding: '0px'
    }
});

class PropertiesControl extends React.Component {
    constructor(props) {
        super(props);

        let availableProperties = [
            'Danceability',
            'Energy',
            'Instramentalness',
            'Liveliness',
            'Loudness',
            'Speechiness',
            'Tempo',
            'Valence'
        ];

        let availableColors = [
            '#FCC5E4',
            '#C5E4FC',
            '#FCDDC5',
            '#C5FCDD',
            '#F995CE',
            '#95CEF9',
            '#F9C195',
            '#95F9C1',
            '#FF66BC',
            '#66BCFF',
            '#F9A463',
            '#63F9A4',
            '#E55BA9',
            '#5BA9E5',
            '#E09459',
            '#59E094'
        ];

        this.state = {
            colors: availableColors,
            properties: availableProperties,
            selected: {},
            availableProperties: availableProperties
        };
    }

    addProperty = (key) => {
        let propertyIndex = Object.keys(this.state.selected).length;
        this.setState(prev => {
            let selected = Object.assign({}, prev.selected);
            selected[key] = { color: this.state.colors[propertyIndex+4], offsetType: 'high', frequency: 1 };
            let selectedProperties = Object.keys(selected);
            let properties = prev.availableProperties.filter(property => !selectedProperties.includes(property));
            return { selected, properties };
        });
    }

    propertyChanged = (key, property) => {
        this.setState(prev => {
            let selected = Object.assign({}, prev.selected);
            selected[key] = property;
            return { selected };
        });
    }

    removeProperty = (key) => {
        this.setState(prev => {
            let selected = Object.assign({}, prev.selected);
            delete selected[key];
            let selectedProperties = Object.keys(selected);
            let properties = prev.availableProperties.filter(property => !selectedProperties.includes(property));
            return { selected, properties };
        });
    }

    render() {
        const classes = this.props.classes;

        const propertiesSettings = (
            <PropertiesSettings 
                properties={this.state.properties} 
                selectedProperties={this.state.selected} 
                colors={this.state.colors} 
                addProperty={this.addProperty} 
                propertyChanged={this.propertyChanged} 
                removeProperty={this.removeProperty}
            />
        );

        const propertiesSettingsFixed = (
            <Paper>
                {propertiesSettings}
            </Paper>
        );

        const propertiesSettingsAccordion = (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <ListItemText
                        primary='Vibes'
                    />
                </AccordionSummary>
                <AccordionDetails divider className={classes.accordionDetail}>
                    {propertiesSettings}
                </AccordionDetails>
            </Accordion>
        );

        const propertiesGraph = (
            <Collapse in={Object.keys(this.state.selected).length > 0}>
                <PropertiesGraph 
                    draw={drawPropertiesGraph} 
                    click={drawMouseLocation} 
                    selected={this.state.selected} 
                />
            </Collapse>
        );

        const propertiesSettingsElement = this.props.size === 'xs' ? propertiesSettingsAccordion : propertiesSettingsFixed;

        return (
            <div className={classes.propertiesControlContainer}>
                <div className={classes.propertySettingsContainer}>
                    {propertiesSettingsElement}   
                </div>
                <div>
                    {propertiesGraph}
                </div>
            </div>
        );
    };
}

export default withStyles(styles)(PropertiesControl)