import { withStyles } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import SearchBar from '../SearchBar/SearchBar';

const styles = (theme) => ({
    editorContainer: {
        display: 'flex'
    },
    selectionContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: '100%'
    },
    chipContainer: {
        minWidth: '100%'
    },
    listItemContainer: {
        minWidth: '100%',
    },
    chipWrapper: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    labelWrapper: {
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    list: {
        height: '180px',
        overflow: 'auto'
    },
    addButton: {
        alignSelf: 'flex-end'
    },
    selectedContainer: {
        minWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    addingContainer: {
        minWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1)
    },
    disabled: {
        backgroundColor: theme.colorScheme.componentDisabled,
        pointerEvents: 'none'
    }
});

class SelectionEditor extends React.Component {
    constructor(props) {
        super(props);

        this.handleRemoveItem = this.handleRemoveItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleOpenAdd = this.handleOpenAdd.bind(this);

        this.state = { isAdding: false };
    }

    handleRemoveItem(i) {
        this.props.onRemoveItem(this.props.type, i);
    }

    handleAddItem(e) {
        this.props.onAddItem(this.props.type, e.target.innerText);
    }

    handleOpenAdd(i) {
        this.setState(prev => {
            let isAdding = !prev.isAdding;
            return { isAdding };
        });
    }

    render () {
        const classes = this.props.classes;
        const data = this.props.list.map((item) => {
            return (
                <ListItem
                    key={'sel-'+item}
                    button
                    onClick={this.handleAddItem}
                >
                    <ListItemText primary={item}/>
                </ListItem>
            );
        });
        const selected = this.props.selected.map((item) => {
            return (
                <ListItem
                    key={'schip-'+item}
                    button
                >
                    <ListItemText primary={item}/>
                    <ListItemSecondaryAction>
                        <IconButton 
                            edge="end" 
                            aria-label="delete"
                            onClick={() => this.handleRemoveItem(item)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });
        const addButton = (
            <IconButton 
                    edge="end" 
                    aria-label="delete"
                    className={classes.addButton}
                    onClick={this.handleOpenAdd}
            >
                <AddIcon />
            </IconButton>
        );
        const removeButton = (
            <div className={classes.searchContainer}>
                <SearchBar />
                <IconButton 
                    edge="end" 
                    aria-label="delete"
                    onClick={this.handleOpenAdd}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        );

        const containerClass = this.state.isAdding ? classes.addingContainer : classes.selectedContainer;
        const containerDisabled = this.props.disabled ? `${containerClass} ${classes.disabled}` : `${containerClass}`;

        const selectedSection = (
            <div className={classes.selectionContainer}>
                <div className={classes.chipContainer}>
                    <div className={classes.labelWrapper}>
                        <FormLabel className={classes.label}>Selected {this.props.display} {addButton}</FormLabel>
                    </div>
                    <List dense={true} className={classes.list}>
                        {selected}
                    </List>
                </div>
            </div>
        );
        const addSection = (
            <div className={classes.listItemContainer}>
                <div className={classes.labelWrapper}>
                    <FormLabel>Suggested {this.props.display}</FormLabel>
                </div>
                <List dense={true} className={classes.list}>
                    {data}
                </List>
            </div>
        );

        const displayButton = this.state.isAdding ? removeButton : null;
        const displaySection = this.state.isAdding ? addSection : selectedSection;

        return (
            <div className={containerDisabled}>
                {displayButton}
                {displaySection}
            </div>
        );
    };
};

export default withStyles(styles)(SelectionEditor);