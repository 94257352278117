import { offsetTypes } from '../PropertiesControl/DrawProperties';
import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import ColorPicker from '../ColorPicker/ColorPicker';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    formLabel: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        minWidth: '150px',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
        }
    },
    formControlContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%'
    },
    colorBox: {
        width: '10px',
        height: '10px'
    },
    floatButtonRight: {
        marginLeft: 'auto',
        marginRight: theme.spacing(1),
    },
    propertyHighlightWrapper: {
        height: '2px',
    },
    propertyHighlight: {
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
        height: '100%',
        width: '100%',
        borderRadius: '0px 0px 2px 2px'
    },
    propertyWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    addProperty: {
        display: 'flex'
    },
    addSelect: {
        minWidth: '100px'
    },
    addSelectWrapper: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
        }
    }
});

class PropertiesSettings extends React.Component {
    constructor(props) {
        super(props);

        this.handlePropertyChange = this.handleOffsetChange.bind(this);
        this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleRemoveProperty = this.handleRemoveProperty.bind(this);
        this.handlePropertySelected = this.handlePropertySelected.bind(this);
        this.handleAddProperty = this.handleAddProperty.bind(this);

        this.state = {
            offsetTypes: offsetTypes,
            selectedProperty: ''
        };
    }

    handleOffsetChange(key, e) {
        let selectedProperty = this.props.selectedProperties[key];
        selectedProperty.offsetType = e.nativeEvent.target.value
        this.props.propertyChanged(key, selectedProperty);
    }

    handleFrequencyChange(key, e) {
        let selectedProperty = this.props.selectedProperties[key];
        selectedProperty.frequency = e.nativeEvent.target.value
        this.props.propertyChanged(key, selectedProperty);
    }

    handleColorChange(key, color) {
        let selectedProperty = this.props.selectedProperties[key];
        selectedProperty.color = color;
        this.props.propertyChanged(key, selectedProperty);
    }

    handleRemoveProperty(key) {
        this.props.removeProperty(key);
    }

    handlePropertySelected(e) {
        this.setState({ selectedProperty: e.target.value });
    }

    handleAddProperty() {
        if(this.state.selectedProperty)
        {
            this.props.addProperty(this.state.selectedProperty);
            this.setState({ selectedProperty: '' });
        }
    }

    render() {
        const classes = this.props.classes;
        const size = this.props.size;
        const isLarge = ['xl', 'lg'].includes(size);

        const propertiesOptions = this.props.properties.map((property) => {
            return (
                <option key={`o-prop-${property}`} value={property}>{property}</option>
            );
        });
        propertiesOptions.unshift(
            <option key={`o-prop-none`} value=''></option>
        );

        const offsetOptions = Object.keys(this.state.offsetTypes).map((type) => {
            let offsetType = this.state.offsetTypes[type];
            return (
                <option key={`o-offset-${type}`} value={type}>{offsetType.display}</option>
            );
        });

        const propertyElements = Object.keys(this.props.selectedProperties).map((key) => {
            let selected = this.props.selectedProperties[key];
            return (
                <div key={`props-${key}`}>
                    <div className={classes.propertyWrapper}>
                        <div>
                            <FormControl className={classes.formLabel}>
                                <FormLabel>
                                    {key}
                                </FormLabel>
                            </FormControl>
                        </div>
                        <div className={classes.formControlContainer}>
                            <FormControl required>
                                <ColorPicker colors={this.props.colors} selected={selected.color} colorSelected={(color) => this.handleColorChange(key, color)}></ColorPicker>
                            </FormControl>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    select
                                    label='Offset'
                                    value={selected.offsetType}
                                    onChange={(e) => this.handleOffsetChange(key, e)}
                                    name='Offset'
                                    SelectProps={{ native: true }}
                                >
                                    {offsetOptions}
                                </TextField>
                            </FormControl>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    defaultValue={selected.frequency}
                                    onChange={(e) => this.handleFrequencyChange(key, e)}
                                    inputProps={{ type: 'number', min: .25, max: 5, step: .25 }}
                                    label='Frequency'
                                />
                            </FormControl>
                            <IconButton 
                                className={classes.floatButtonRight}
                                edge='end' 
                                aria-label='delete'
                                onClick={() => this.handleRemoveProperty(key)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                    <Divider className={classes.divider} variant='middle' />
                </div>
            );
        });

        const addPropertyElement = (
            <div key='add-prop-btn' className={`${classes.propertyWrapper} ${classes.addProperty}`}>
                <FormControl className={`${classes.formControl} ${classes.addSelectWrapper}`}>
                    <TextField
                        select
                        label='Property'
                        value={this.state.selectedProperty}
                        onChange={this.handlePropertySelected}
                        SelectProps={{ native: true }}
                        className={classes.addSelect}
                    >
                        {propertiesOptions}
                    </TextField>
                </FormControl>
                <IconButton 
                    disabled={Boolean(!this.state.selectedProperty)}
                    edge='end' 
                    aria-label='delete'
                    className={classes.floatButtonRight}
                    onClick={this.handleAddProperty}
                >
                    <AddIcon />
                </IconButton>
            </div>
        );

        return (
            <div>
                {propertyElements}
                {addPropertyElement}
            </div>
        );
    }
}

export default withStyles(styles)(PropertiesSettings);