import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    containerRoot: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: '100%'
    }
}));

export default function ContentContainer(props) {
    const classes = useStyles();

    return (
        <Container className={classes.containerRoot}>
            {props.children}
        </Container>
    );
}