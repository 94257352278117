import './App.css';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import ContentContainer from './components/ContentContainer/ConentContainer';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavBar from './components/NavBar/NavBar';
import SuggestionsControl from './components/SuggestionsControl/SuggestionsControl';
import PlaylistSettings from './components/PlaylistSettings/PlaylistSettings';
import PropertiesControl from './components/PropertiesControl/PropertiesControl';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';

function App(props) {
  let theme = createMuiTheme({ 
    typography: { 
      fontFamily: ['Roboto'] 
    },
    palette: {
      primary: {
        light: '#F9EDF4',
        main: '#F995CE',
        dark: '#FF66BC',
        contrastText: '#161616'
      },
      secondary: {
        light: '#EDF4F9',
        main: '#95CEF9',
        dark: '#66BCFF',
        contrastText: '#161616'
      }
    },
    colorScheme: {
      text: '#161616',
      primaryBackground: '#EDF4F9',
      primaryAccent: '#F995CE',
      primaryAccentDarker: '#FF66BC',
      primaryAccentText: '#661441',
      secondaryBackground: '#F9EDF4',
      secondaryAccent1: '#95CEF9',
      secondaryAccent1Darker: '#66BCFF',
      secondaryAccent2: '#95F9C1',
      secondaryAccent2Darker: '#63F9A4',
      secondaryAccent3: '#F9C195',
      secondaryAccent3Darker: '#F9A463',
      componentBackground: '#FFFFFF',
      componentAccent: '#999999',
      componentDisabled: '#E5E5E5'
    }
  });
  theme = responsiveFontSizes(theme);

  const { width } = props;

  return (
    <div className="app">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <NavBar></NavBar>
        <ContentContainer>
          <PlaylistSettings size={width}></PlaylistSettings>
          <SuggestionsControl size={width}></SuggestionsControl>
          <PropertiesControl size={width}></PropertiesControl>
        </ContentContainer>
      </ThemeProvider>
    </div>
  );
}

App.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
}

export default withWidth()(App);
