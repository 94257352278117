import { withStyles } from '@material-ui/core/styles';

import React from 'react';

const styles = (theme) => ({
    graphCanvas: {
        display: 'block',
        width: '100%',
        height: '100%'
    }
});

class GraphCanvas extends React.Component {
    constructor(props) {
        super(props);

        this.reDraw = this.reDraw.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            ref: React.createRef(),
            context: {},
            animationFrameId: null,
            width: 0,
            height: 0
        };
    }

    reDraw = () => {
        this.props.draw(this.state.context, this.props.selected);
    }

    handleClick = (e) => {
        this.props.click(this.state.context, e, this.props.selected);
    }

    componentDidMount() {
        let canvas = this.state.ref.current;
        let context = canvas.getContext('2d');

        let width = canvas.offsetWidth;
        let height = canvas.offsetHeight;
        
        context.canvas.width = width*3;
        context.canvas.height = height*3;

        this.setState({
            context: context,
            animationFrameId: window.requestAnimationFrame(this.reDraw),
            width: width,
            height: height
        });
    }

    componentDidUpdate() {
        this.reDraw();
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.state.animationFrameId);
    }

    render() {
        const classes = this.props.classes;

        return (
            <canvas ref={this.state.ref} className={classes.graphCanvas} onClick={this.handleClick} />
        );
    }
};

export default withStyles(styles)(GraphCanvas);