import createStyles from "@material-ui/styles/createStyles";

const offsetTypes = {
    high: { value: 0.75, display: 'High' },
    low: { value: 0.25, display: 'Low' },
    midLow: { value: 0, display: 'Mid-Low' },
    midHigh: { value: 0.5, display: 'Mid-High' }
};

function getOffsetAxes(height) { 
    return {
        x: .05*height, 
        y: .05*height 
    }
};

function getAmplitude(height, offsetAxes) {
    return (height/2)-(offsetAxes.y/2)-12;
}

function getYSin(x, height, width, amplitude, frequency, offsetAxes, shift) {
    return (height/2 + amplitude * Math.sin((x-shift-offsetAxes.x)/(width/(2*Math.PI)/frequency)))-(offsetAxes.y/2);
}

function drawAxes(ctx, offsetAxes) {
    let width = ctx.canvas.width;
    let height = ctx.canvas.height;
    let xOffset = offsetAxes.x;
    let yOffset = offsetAxes.y;

    ctx.beginPath();
    ctx.setLineDash([]);
    ctx.lineWidth = 3;
    ctx.strokeStyle = '#999999';

    ctx.moveTo(xOffset, 0);
    ctx.lineTo(xOffset, height);
    ctx.moveTo(0, height-yOffset);
    ctx.lineTo(width, height-yOffset);

    ctx.stroke();
};

function drawProperty(ctx, offsetAxes, property) {
    let width = ctx.canvas.width-offsetAxes.x;
    let height = ctx.canvas.height;
    let frequency = property.frequency;
    let shift = offsetTypes[property.offsetType].value * width / frequency;

    ctx.beginPath();
    ctx.setLineDash([]);
    ctx.lineWidth = 3;
    ctx.strokeStyle = property.color;

    let x = offsetAxes.x;
    let y = 0;
    let amplitude = getAmplitude(height, offsetAxes);

    while (x < width+offsetAxes.x) {
        y = getYSin(x, height, width, amplitude, frequency, offsetAxes, shift);
        ctx.lineTo(x, y);
        x+=5;
    }
    ctx.stroke();
    ctx.save();

    ctx.stroke();
    ctx.restore();
};

function drawDetails(ctx, event, properties, offsetAxes) {
    let width = ctx.canvas.width-offsetAxes.x;
    let height = ctx.canvas.height;
    let amplitude = getAmplitude(height, offsetAxes);
    let x = (event.pageX - ctx.canvas.offsetLeft)*3;

    if(x-offsetAxes.x > 0)
    {
        ctx.beginPath();
        ctx.setLineDash([]);
        ctx.lineWidth = 3;
        ctx.strokeStyle = '#999999';

        ctx.moveTo(x, 6);
        ctx.lineTo(x, height-offsetAxes.y);

        ctx.stroke();
        ctx.save();

        let radius = 7;

        Object.keys(properties).forEach((key) => {
            let prop = properties[key];
            ctx.beginPath();
            let shift = offsetTypes[prop.offsetType].value * width / prop.frequency;
            let y = getYSin(x, height, width, amplitude, prop.frequency, offsetAxes, shift);
            ctx.arc(x, y, radius, 0, 2*Math.PI, false);
            ctx.strokeStyle = prop.color;
            ctx.fillStyle = prop.color;
            ctx.fill();
            ctx.stroke();
        });
    }
};

function drawPropertiesGraph(ctx, properties) {
    let offsetAxes = getOffsetAxes(ctx.canvas.height);
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

    Object.keys(properties).forEach((key) => {
        drawProperty(ctx, offsetAxes, properties[key]);
    });
    drawAxes(ctx, offsetAxes);
}

function drawMouseLocation (ctx, clickEvent, properties) {
    drawPropertiesGraph(ctx, properties);

    let offsetAxes = getOffsetAxes(ctx.canvas.height);
    drawDetails(ctx, clickEvent, properties, offsetAxes);
};

export { drawPropertiesGraph, drawMouseLocation, offsetTypes };