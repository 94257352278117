export default function Toast() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 200 200">
            <g id="Layer_2">
                <path fill="#F995CE" d="M166.24,39.24c1.75,2.48,2.729,5.27,2.729,8.21v54.47c0,10.36-12.04,18.76-26.89,18.76H60.36
                    c-14.86,0-26.89-8.399-26.89-18.76V47.45c0-4.13,1.92-7.96,5.18-11.06c-3.29-2.67-5.18-5.8-5.18-9.16
                    c0-9.59,15.47-17.37,34.54-17.37c14.19,0,26.37,4.29,31.7,10.43c6.51-6.26,18.4-10.43,31.99-10.43c20.59,0,37.27,9.58,37.27,21.38
                    C168.97,34.07,168.01,36.78,166.24,39.24z"/>
            </g>
            <g id="Layer_1" display="none">
                <path display="inline" fill="#F995CE" d="M164.404,41.715c1.662,2.31,2.59,4.917,2.59,7.658v70.355
                    c0,9.654-11.452,17.486-25.583,17.486H63.657c-14.131,0-25.584-7.832-25.584-17.486V49.373c0-3.849,1.828-7.427,4.931-10.317
                    c-3.129-2.485-4.931-5.401-4.931-8.534c0-8.939,14.72-16.192,32.868-16.192c13.494,0,25.084,3.998,30.151,9.723
                    c6.197-5.834,17.514-9.723,30.442-9.723c19.59,0,35.46,8.926,35.46,19.931C166.994,36.895,166.078,39.419,164.404,41.715z"/>
                <path display="inline" fill="#F995CE" d="M55.613,34.73c0,0.399-0.293,0.724-0.658,0.724h-0.438c-0.364,0-0.657-0.325-0.657-0.724
                    l0,0c0-0.4,0.293-0.725,0.657-0.725h0.438C55.319,34.005,55.613,34.33,55.613,34.73L55.613,34.73z"/>
                <path display="inline" fill="#F995CE" d="M130.599,63.701c0,0.399-0.294,0.725-0.657,0.725h-0.439
                    c-0.364,0-0.657-0.325-0.657-0.725l0,0c0-0.399,0.293-0.724,0.657-0.724h0.439C130.305,62.977,130.599,63.301,130.599,63.701
                    L130.599,63.701z"/>
                <path display="inline" fill="#F995CE" d="M138.492,43.421c0,0.399-0.295,0.725-0.658,0.725h-0.438
                    c-0.363,0-0.658-0.325-0.658-0.725l0,0c0-0.399,0.295-0.724,0.658-0.724h0.438C138.197,42.697,138.492,43.021,138.492,43.421
                    L138.492,43.421z"/>
                <path display="inline" fill="#F995CE" d="M93.764,30.384c0,0.399-0.294,0.724-0.657,0.724h-0.439c-0.364,0-0.658-0.325-0.658-0.724
                    l0,0c0-0.4,0.294-0.725,0.658-0.725h0.439C93.47,29.66,93.764,29.984,93.764,30.384L93.764,30.384z"/>
                <path display="inline" fill="#F995CE" d="M127.968,27.487c0,0.399-0.295,0.724-0.658,0.724h-0.438
                    c-0.364,0-0.658-0.325-0.658-0.724l0,0c0-0.399,0.294-0.725,0.658-0.725h0.438C127.673,26.763,127.968,27.088,127.968,27.487
                    L127.968,27.487z"/>
                <path display="inline" fill="#F995CE" d="M117.443,55.01c0,0.399-0.294,0.724-0.657,0.724h-0.439c-0.364,0-0.657-0.325-0.657-0.724
                    l0,0c0-0.4,0.293-0.725,0.657-0.725h0.439C117.149,54.285,117.443,54.61,117.443,55.01L117.443,55.01z"/>
                <path display="inline" fill="#F995CE" d="M154.278,59.355c0,0.4-0.295,0.725-0.658,0.725h-0.438c-0.363,0-0.658-0.325-0.658-0.725
                    l0,0c0-0.399,0.295-0.724,0.658-0.724h0.438C153.983,58.631,154.278,58.956,154.278,59.355L154.278,59.355z"/>
                <path display="inline" fill="#F995CE" d="M77.978,20.244c0,0.4-0.294,0.725-0.658,0.725H76.88c-0.363,0-0.658-0.325-0.658-0.725
                    l0,0c0-0.399,0.294-0.724,0.658-0.724h0.439C77.683,19.52,77.978,19.845,77.978,20.244L77.978,20.244z"/>
                <path display="inline" fill="#F995CE" d="M79.293,41.973c0,0.4-0.294,0.725-0.658,0.725h-0.439c-0.363,0-0.658-0.325-0.658-0.725
                    l0,0c0-0.399,0.294-0.724,0.658-0.724h0.439C78.999,41.249,79.293,41.573,79.293,41.973L79.293,41.973z"/>
                <path display="inline" fill="#F995CE" d="M96.395,68.046c0,0.399-0.294,0.724-0.657,0.724h-0.439c-0.364,0-0.658-0.325-0.658-0.724
                    l0,0c0-0.399,0.294-0.725,0.658-0.725h0.439C96.101,67.322,96.395,67.647,96.395,68.046L96.395,68.046z"/>
                <path display="inline" fill="#F995CE" d="M74.031,57.906c0,0.4-0.294,0.725-0.657,0.725h-0.44c-0.363,0-0.657-0.324-0.657-0.725
                    l0,0c0-0.399,0.294-0.724,0.657-0.724h0.44C73.736,57.183,74.031,57.507,74.031,57.906L74.031,57.906z"/>
                <path display="inline" fill="#F995CE" d="M50.351,52.113c0,0.399-0.294,0.724-0.657,0.724h-0.44c-0.363,0-0.657-0.324-0.657-0.724
                    l0,0c0-0.4,0.294-0.725,0.657-0.725h0.44C50.057,51.388,50.351,51.713,50.351,52.113L50.351,52.113z"/>
                <path display="inline" fill="#F995CE" d="M56.929,78.186c0,0.4-0.294,0.725-0.657,0.725h-0.44c-0.363,0-0.657-0.325-0.657-0.725
                    l0,0c0-0.399,0.294-0.724,0.657-0.724h0.44C56.635,77.462,56.929,77.787,56.929,78.186L56.929,78.186z"/>
                <path display="inline" fill="#F995CE" d="M145.07,76.738c0,0.399-0.295,0.724-0.658,0.724h-0.439c-0.363,0-0.658-0.324-0.658-0.724
                    l0,0c0-0.4,0.295-0.725,0.658-0.725h0.439C144.775,76.014,145.07,76.338,145.07,76.738L145.07,76.738z"/>
                <path display="inline" fill="#F995CE" d="M124.021,83.98c0,0.4-0.294,0.725-0.657,0.725h-0.44c-0.363,0-0.657-0.325-0.657-0.725
                    l0,0c0-0.399,0.294-0.724,0.657-0.724h0.44C123.728,83.256,124.021,83.581,124.021,83.98L124.021,83.98z"/>
                <path display="inline" fill="#F995CE" d="M85.871,78.186c0,0.4-0.294,0.725-0.658,0.725h-0.439c-0.363,0-0.658-0.325-0.658-0.725
                    l0,0c0-0.399,0.294-0.724,0.658-0.724h0.439C85.577,77.462,85.871,77.787,85.871,78.186L85.871,78.186z"/>
                <path display="inline" fill="#F995CE" d="M106.919,92.672c0,0.399-0.294,0.724-0.657,0.724h-0.439
                    c-0.364,0-0.657-0.325-0.657-0.724l0,0c0-0.4,0.293-0.725,0.657-0.725h0.439C106.625,91.947,106.919,92.272,106.919,92.672
                    L106.919,92.672z"/>
                <path display="inline" fill="#F995CE" d="M147.701,89.775c0,0.399-0.295,0.724-0.658,0.724h-0.439
                    c-0.363,0-0.658-0.325-0.658-0.724l0,0c0-0.399,0.295-0.725,0.658-0.725h0.439C147.406,89.05,147.701,89.375,147.701,89.775
                    L147.701,89.775z"/>
                <path display="inline" fill="#F995CE" d="M72.715,91.224c0,0.399-0.294,0.724-0.658,0.724h-0.439c-0.363,0-0.658-0.324-0.658-0.724
                    l0,0c0-0.4,0.294-0.725,0.658-0.725h0.439C72.421,90.499,72.715,90.823,72.715,91.224L72.715,91.224z"/>
            </g>
            <g id="Layer_3">
                <path fill="#5FFFC1" d="M33.481,141.682h-0.013v-1.086C33.468,140.959,33.468,141.318,33.481,141.682z"/>
                <path fill="#5FFFC1" d="M69.474,141.682h-0.105c0-0.045,0-0.072,0.013-0.115C69.408,141.596,69.435,141.637,69.474,141.682z"/>
                <path fill="#5FFFC1" d="M69.474,141.682h-0.105c0-0.045,0-0.072,0.013-0.115C69.408,141.596,69.435,141.637,69.474,141.682z"/>
                <path fill="#5FFFC1" d="M69.474,141.682h-0.105c0-0.045,0-0.072,0.013-0.115C69.408,141.596,69.435,141.637,69.474,141.682z"/>
                <path fill="#F995CE" d="M158.484,79.311c-2.763,0-5.275,0.812-7.143,2.159l-0.014,0.014c-2.17,1.695-5.117,2.723-8.34,2.723
                    c-3.46,0-6.577-1.188-8.787-3.085h-0.015c-5.354-3.158-13.654-5.186-22.97-5.186c-5.867,0-11.352,0.812-15.943,2.202
                    c-1.671,0.883-3.565,1.391-5.564,1.391c-2.513,0-4.868-0.797-6.802-2.145h-0.039c-5.236-2.317-12.314-3.751-20.128-3.751h-9.209
                    c-11.077,0-20.062,5.722-20.062,12.792v27.349c0,0.361,0,0.723,0.014,1.084c0.145,12.721,2.985,23.338,6.84,26.656
                    c0.014,0.014,0.014,0.027,0.027,0.027c1.973,2.289,4.959,3.736,8.301,3.736c0.895,0,1.75-0.102,2.578-0.303
                    c1.014-0.551,2.184-0.928,3.447-1.057c1.617-0.438,3.498-0.684,5.484-0.684c5.434,0,9.959,1.842,10.934,4.26
                    c1.248,1.521,2.025,3.752,2.025,6.215c0,1.115-0.158,2.174-0.447,3.158c0.013,0.057,0.039,0.102,0.053,0.145
                    c-0.093,0.158-0.184,0.318-0.276,0.477c-0.171,0.35-0.329,0.711-0.474,1.088c-0.421,1.131-0.658,2.377-0.658,3.695
                    c0,4.779,3.039,8.66,6.789,8.66c3.762,0,6.801-3.881,6.801-8.66c0-1.203-0.197-2.348-0.553-3.391
                    c-0.447-0.781-0.842-1.725-1.184-2.768c-0.145-0.607-0.303-1.188-0.475-1.752c-0.091-0.32-0.157-0.652-0.223-1
                    c-0.29-1.578-0.447-3.287-0.447-5.084c0-3.955,0.775-7.547,2.025-9.996c0.975-3.881,5.499-6.85,10.932-6.85
                    c1.987,0,3.869,0.404,5.487,1.1c1.263,0.219,2.433,0.811,3.445,1.695c0.276,0.246,0.54,0.506,0.776,0.795
                    c0.014,0,0.014,0.016,0.027,0.031c0.171,0.174,0.315,0.346,0.447,0.535c0,0,0,0,0,0.014c0.024,0.016,0.024,0.016,0.039,0.045
                    c0.42,0.65,0.724,1.535,0.828,2.535v0.014c0.145,0.492,0.236,1.188,0.236,1.941c0,0.666-0.065,1.289-0.183,1.752
                    c0,0.016,0,0.029,0.012,0.045c-0.012,0.027-0.039,0.057-0.052,0.086c-0.053,0.219-0.131,0.406-0.224,0.551
                    c-0.118,0.406-0.264,0.736-0.435,0.986c-0.145,0.52-0.223,1.084-0.223,1.664c0,3.1,2.209,5.605,4.933,5.605
                    s4.933-2.506,4.933-5.605c0-1.16-0.303-2.232-0.841-3.129c-0.026-0.043-0.066-0.086-0.093-0.145v-0.014
                    c-0.026-0.016-0.039-0.029-0.053-0.059c-0.013-0.016-0.013-0.029-0.025-0.059c-0.158-0.318-0.303-0.754-0.408-1.26
                    c-0.105-0.203-0.171-0.465-0.224-0.754c-0.013-0.059-0.04-0.115-0.053-0.172c-0.106-0.523-0.158-1.219-0.158-1.957
                    c-0.013-0.131-0.013-0.275-0.013-0.406c0-1.029,0.092-1.955,0.224-2.621v-0.031c0.104-1.346,0.422-2.533,0.828-3.416
                    c0.027-0.029,0.027-0.029,0.053-0.059c0-0.014,0-0.014,0-0.014c0.065-0.115,0.118-0.246,0.198-0.361
                    c0-0.016,0.013-0.031,0.025-0.045c0.526-1.131,2.276-2.043,4.579-2.391c0.446-0.115,0.92-0.203,1.406-0.275
                    c1.935-0.275,3.698-0.174,4.896,0.232c1.406,0.23,3.131,1.115,4.748,2.549c1.118,0.986,2.013,2.086,2.618,3.145
                    c0.013,0.014,0.013,0.027,0.026,0.043c1.671,2.303,3.604,7.764,4.934,14.398c0.748,3.68,1.195,7.17,1.354,10.082
                    c0.054,0.666,0.066,1.289,0.066,1.883c0,0.232-0.013,0.465-0.026,0.682c0,0.145-0.013,0.291-0.027,0.42
                    c0,0.275-0.038,0.551-0.091,0.855c-0.093,0.621-0.224,1.26-0.422,1.91c-0.263,0.914-0.604,1.754-0.985,2.492
                    c-0.355,1.014-0.554,2.115-0.554,3.273c0,4.404,2.737,7.939,6.104,7.939c3.264,0,5.921-3.305,6.093-7.49
                    c0.038-0.305,0.065-0.609,0.065-0.928c0-0.926-0.198-1.797-0.54-2.578c-0.132-0.305-0.275-0.594-0.46-0.869
                    c-0.014-0.027-0.039-0.059-0.053-0.086c-0.315-0.465-0.606-0.986-0.867-1.564c-0.067-0.131-0.119-0.275-0.171-0.42
                    c-0.226-0.334-0.422-0.871-0.58-1.564c-0.013-0.014-0.013-0.043-0.025-0.059c-0.079-0.246-0.146-0.508-0.211-0.754
                    c-0.197-0.725-0.329-1.432-0.422-2.143c-0.052-0.318-0.092-0.652-0.092-0.941v-0.059c-0.014-0.174-0.025-0.363-0.025-0.537
                    c-0.013-0.332-0.027-0.666-0.027-1.012c0-0.799,0.015-1.652,0.066-2.58c-0.014-1.072,0-2.186,0.039-3.33
                    c0.053-1.609,0.158-3.145,0.303-4.564c0,0,0,0,0-0.014c0.026-1.246,0.158-2.664,0.395-4.172c0.395-2.578,1.026-4.838,1.71-6.33
                    c0.013-0.029,0.027-0.059,0.04-0.088c0.053-0.131,0.118-0.246,0.171-0.363c0.788-1.664,1.907-2.75,3.025-2.822h0.04
                    c1.013-0.289,2.236-0.145,3.407,0.506c0.368,0.203,0.71,0.449,1.014,0.725c1.143,0.607,2.552,0.955,4.064,0.955
                    c1.394,0,2.696-0.303,3.802-0.826c0.657-0.303,1.249-0.693,1.749-1.143c0.013,0,0.013-0.014,0.013-0.014
                    c0.527-0.564,1.014-1.289,1.434-2.16c1.106-2.273,1.817-5.49,1.869-9.08h0.026V87.28C168.97,82.875,164.272,79.311,158.484,79.311z
                    M69.37,124.086c0-0.045,0-0.072,0.014-0.115c0.025,0.029,0.053,0.07,0.092,0.115H69.37z M69.37,114.857
                    c0-0.043,0-0.07,0.014-0.115c0.025,0.029,0.053,0.072,0.092,0.115H69.37z"/>
                <path d="M69.382,141.566c-0.013,0.043-0.013,0.07-0.013,0.115h0.105C69.435,141.637,69.408,141.596,69.382,141.566z"/>
                <path d="M69.474,141.682h-0.105c0-0.045,0-0.072,0.013-0.115C69.408,141.596,69.435,141.637,69.474,141.682z"/>
                <path d="M69.474,132.453h-0.105c0-0.043,0-0.07,0.013-0.115C69.408,132.367,69.435,132.41,69.474,132.453z"/>
                <path d="M69.474,141.682h-0.105c0-0.045,0-0.072,0.013-0.115C69.408,141.596,69.435,141.637,69.474,141.682z"/>
                <path fill="none" d="M69.474,132.453h-0.105c0-0.043,0-0.07,0.013-0.115C69.408,132.367,69.435,132.41,69.474,132.453z"/>
                <path fill="#F995CE" d="M83.064,98.623c0.554,1.723,2.537,3.086,5.325,3.4c2.379,0.275,4.805-0.289,6.565-1.37
                    c-1.907,0.716-4.04,1.021-6.128,0.78C86.276,101.142,84.27,100.093,83.064,98.623z"/>
                <path fill="#F995CE" d="M133.849,100.357c0.753,2.426,3.28,4.41,6.777,4.963c2.985,0.482,6.001-0.203,8.168-1.641
                    c-2.361,0.922-5.018,1.256-7.641,0.836C137.954,104.002,135.408,102.459,133.849,100.357z"/>
            </g>
        </svg>
    );
}