import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DropZone from '../DropZone/DropZone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';


const styles = (theme) => ({
    accordionDetail: {
        padding: theme.spacing(0),
        display: 'block',
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        padding: '0px'
    },
    settingsElementWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    settingsContainer: {
        width: '626px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            gridTemplateColumns: '1fr'
        }
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1)
        }
    },
    inputControl: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
        }
    }
});

class PlaylistSettings extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const classes = this.props.classes;
        const playlistSettingsControls = (
            <div className={classes.settingsContainer}>
                <div className={classes.inputContainer}>
                    <TextField
                        className={classes.inputControl}
                        id='playlist-name'
                        label='Name'
                    />
                    <TextField
                        className={classes.inputControl}
                        id="playlist-description"
                        label="Description"
                        multiline
                        rows={4}
                    />
                    <FormControlLabel
                        className={classes.inputControl}
                        key='playlist-public'
                        label='Public'
                        control={<Switch color="secondary" />}
                    />
                    <FormControlLabel
                        className={classes.inputControl}
                        key='playlist-collab'
                        label='Collaborative'
                        control={<Switch color="secondary" />}
                    />
                </div>
                <DropZone></DropZone>
            </div>
        );
        
        const playlistSettingsElement = (
            <div className={classes.settingsElementWrapper}>
                <Paper>
                    {playlistSettingsControls}
                </Paper>
            </div>
        );

        const playlistSettingsAccordion = (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <ListItemText
                        primary='Playlist Settings'
                    />
                </AccordionSummary>
                <AccordionDetails divider className={classes.accordionDetail}>
                    {playlistSettingsControls}
                </AccordionDetails>
            </Accordion>
        );

        const playlistSettings = this.props.size === 'xs' ? playlistSettingsAccordion : playlistSettingsElement;

        return (
            <div className={classes.settingsWrapper}>
                {playlistSettings}
            </div>
        );
    }
}

export default withStyles(styles)(PlaylistSettings);