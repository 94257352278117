import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import Typography from '@material-ui/core/Typography';


const styles = (theme) => ({
    drawerList: {
        minWidth: '400px',
        [theme.breakpoints.down('xs')]: {
            minWidth: '250px'
        }
    },
    bottomTextContainer: {
        marginTop: 'auto',
        textAlign: 'center'
    },
    bottomText: {
        color: theme.colorScheme.componentAccent
    }
});

class NavDrawer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const classes = this.props.classes;

        const navMenu = ['Home', 'New Playlist'].map((item, index) => {
            return (
                <ListItem button key={item}>
                    <ListItemIcon>{index !== 1 ? <HomeIcon /> : <AddIcon /> }</ListItemIcon>
                    <ListItemText primary={item} />
                </ListItem>
            );
        })

        return (
            <Drawer anchor='left' open={this.props.open} onClose={this.props.closeDrawer} >
                <List className={classes.drawerList}>
                    {navMenu}
                </List>
                <Divider />
                <div className={classes.bottomTextContainer}>
                    <Typography variant='caption' className={classes.bottomText}>&copy;slipperytoast.com 2021</Typography>
                </div>
            </Drawer>
        );
    }
}

export default withStyles(styles)(NavDrawer);