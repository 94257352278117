import { withStyles } from '@material-ui/core/styles';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React from 'react';
import Typography from '@material-ui/core/Typography';


const styles = (theme) => ({
    dropZoneWrapper: {
        width: '100%',
        height: '100%',
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        }
    },
    dropZone: {
        backgroundColor: theme.colorScheme.componentBackground,
        width: '100%',
        height: '100%',
        border: `1px dashed ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(6)}px 0px`
        }
    },
    dropZoneContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.dark
    },
    dropImage: {
        fontSize: '2.5rem',
        color: theme.palette.primary.main
    }
});

class DropZone extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.dropZoneWrapper}>
                <div className={classes.dropZone}>
                    <div className={classes.dropZoneContent}>
                        <Typography variant='h6'>Upload Cover Image</Typography>
                        <CloudUploadIcon className={classes.dropImage} />
                        <Typography variant='subtitle2'>Drag &amp; Drop or Click</Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(DropZone);