import { withStyles } from '@material-ui/core/styles';

import GraphCanvas from './GraphCanvas';
import Paper from '@material-ui/core/Paper';
import React from 'react';

const styles = (theme) => ({
    graphWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    graphContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'inline-block',
        width: '626px',
        height: '305px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto'
        }
    }
});

class PropertiesGraph extends React.Component {
    constructor(props) {
        super(props);
    };

    render() {
        const { draw, click } = this.props;
        const classes = this.props.classes;

        return (
            <div className={classes.graphWrapper}>
                <Paper className={classes.graphContainer}>
                    <GraphCanvas draw={draw} click={click} selected={this.props.selected} ></GraphCanvas>
                </Paper>
            </div>
        );
    };
}

export default withStyles(styles)(PropertiesGraph)